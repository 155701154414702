const styleEstado = (estado) => {
  let estadoSwitch = estado.toUpperCase();
  switch (estadoSwitch) {
    case "NÚMERO INVÁLIDO":
      return "unqualified";
    case "CONTESTADOR":
      return "negotiation";
    case "NO SE PUDO CONTACTAR":
      return "renewal";
    case "PENDIENTE LLAMAR":
      return "new";
    default:
      return "qualified";
  }
};

export { styleEstado };
