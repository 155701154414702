import React from "react";
import { styleEstado } from "../utils/state.uitls";
import { ShowView } from "../common/components/ShowView";
import { viewTypes } from "../common/types/views.type";
import { useFetchSurveyDuration } from "../hooks/useDuration";

export const Estados = ({ estadoFinal, surveyReponseId }) => {
  const {
    vnDetalleCampana,
    vfVerSeteosCampana,
    vnListadoCampana,
    vfVerDuracion,
  } = viewTypes;

  const showSurveyId = ShowView(vfVerSeteosCampana, vnDetalleCampana);
  const showDuration = ShowView(vfVerDuracion, vnListadoCampana);

  const Duration = ({ surveyId }) => {
    const { data, error, isLoading } = useFetchSurveyDuration(surveyId);

    if (isLoading) return <i className="pi pi-spin pi-spinner"></i>;
    if (error) return <span>Error</span>;

    const duration = data ? data.durationCall : 0;
    return <span>{duration}</span>;
  };

  return (
    <>
      <div className="card flex-estado">
        {showSurveyId && (
          <span>
            Id: <span className="survey-id">{surveyReponseId}</span>
          </span>
        )}

        <div>
          <div className="tagEstado">
            <span
              className={
                "customer-badge status-" +
                styleEstado(estadoFinal) +
                " text-status"
              }
            >
              {estadoFinal}
            </span>
          </div>
        </div>

        {showDuration && (
          <span>
            Duración total:{" "}
            <span className="survey-id">
              <Duration surveyId={sessionStorage.getItem("surveyReponseId")} />
            </span>
          </span>
        )}
      </div>
    </>
  );
};
