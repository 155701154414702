import React, { useEffect } from "react";
import { SurveyResponse } from "./components/SurveyResponse";
import { Container } from "react-bootstrap";
import AuthDataService from "./common/services/auth.service";
import ConfigEnviroment from "./config/ConfigEnviroment";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export default function Root(props) {
  const queryClient = new QueryClient();

  useEffect(() => {
    if (props.name === "@froneus/evi-surveyresponse-detail") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <Container>
      <QueryClientProvider client={queryClient}>
        <div className="surveyresponsedetail evi-surveyresponse-detail">
          <SurveyResponse {...props} />
        </div>
      </QueryClientProvider>
    </Container>
  );
}
