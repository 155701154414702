import { Button } from "primereact/button";

export const CsvDataComponent = ({ csvData }) => {
  const cargarIcono = (data) => {
    let icon = "pi pi-bookmark";

    switch (true) {
      case data.key.toUpperCase().includes("MONTO") ||
        data.key.toUpperCase().includes("SALDO") ||
        data.key.toUpperCase().includes("CREDITO") ||
        data.key.toUpperCase().includes("DINERO"):
        icon = "pi pi-dollar";
        break;

      case data.key.toUpperCase().includes("NOMBRE") ||
        data.key.toUpperCase().includes("APELLIDO") ||
        data.key.toUpperCase().includes("PERSONA"):
        icon = "pi pi-user";
        break;

      case data.key.toUpperCase().includes("DIA") ||
        data.key.toUpperCase().includes("MES") ||
        data.key.toUpperCase().includes("AÑO") ||
        data.key.toUpperCase().includes("CALENDARIO") ||
        data.key.toUpperCase().includes("FECHA"):
        icon = "pi pi-calendar";
        break;

      case data.key.toUpperCase().includes("TELEFONO"):
        icon = "pi pi-phone";
        break;
    }
    return icon;
  };

  return (
    <>
      {Object.keys(csvData).map((key) => (
        <li key={key}>
          <div className="person-item">
            <div className="person-info">
              <div className="person-subtext">{key}</div>
              <div className="person-subtext">
                <strong> {csvData[key]} </strong>
              </div>
            </div>
          </div>
          <div className="person-actions">
            <Button
              type="button"
              className="p-button-rounded p-button-secondary disable-mouse"
              icon={cargarIcono({ key })}
            ></Button>
          </div>
        </li>
      ))}
    </>
  );
};
