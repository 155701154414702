import http from "../common/components/http-common";
class SurveyResponseService {
  obtenerSurveyResponse(
    idSurveyResponse,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get("/surveyResponse/surveyResponseDetail/" + idSurveyResponse);
  }

  obtenerAudioFile(
    idUser,
    idEncuesta,
    idSurveyResponse,
    uniqueId,
    day,
    from,
    to,
    campaignType,
    userName,
    viewName,
    viewFunction
  ) {
    const params = {
      bucket: "froneus-bk2",
      uniqueId: uniqueId || null,
      idUser: idUser || null,
      idEncuesta: idEncuesta || null,
      idSurveyResponse: idSurveyResponse || null,
      day: day || null,
      from: from || null,
      to: to || null,
      campaignType: campaignType,
    };

    const config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      responseType: "blob" as const,
      params,
    };

    return http.get("v1/call/audio", config);
  }
}

export default new SurveyResponseService();
